import {
  getAllQueries,
  getAllQueriesSuccess,
  getAllQueriesFailure,
  runQueryById,
  runQueryByIdSuccess,
  runQueryByIdFailure,
  reset,
} from "app/store/actions/insight"
import { handleActions } from 'redux-actions';

const initialState = {
  queries: {
    loading: false,
    data: null,
    error: null,
  },
  queryData: {
  },
}

const insightReducer = handleActions(
  {
    [getAllQueries]: (state) => ({ 
      ...state,
      queries: {
        loading: true,
        data: null,
        error: null,
      },
    }),

    [getAllQueriesSuccess]: (state, data) => ({
      ...state,
      queries: {
        loading: false,
        data: data.payload?.queries,
        error: null,
      },
    }),

    [getAllQueriesFailure]: (state, data) => ({ 
      ...state,
      queries: {
        loading: false,
        data: null,
        error: data.payload,
      },
    }),

    [runQueryById]: (state, data) => {
      const { query: { queryId }, dateRange } = data.payload;
    
      const id = `${queryId}-${dateRange}`;
    
      // make a deep copy of the queryData in the state using JSON.parse and JSON.stringify
      const queryData = JSON.parse(JSON.stringify(state.queryData));
    
      // initialize the id object if it does not exist
      if (!queryData[id]) {
        queryData[id] = {};
      }
    
      // modify the deep-copied queryData
      queryData[id].loading = true;
      queryData[id].data = null;
      queryData[id].refreshedAt = null;
      queryData[id].error = null;
    
      return { 
        ...state,
        queryData,
      };
    },

    [runQueryByIdSuccess]: (state, data) => {
      const { queryId, dateRange, resp, refreshedAt } = data.payload;
    
      // make a deep copy of the queryData in the state using JSON.parse and JSON.stringify
      const queryData = JSON.parse(JSON.stringify(state.queryData));
      
      // create an id based on the queryId and dateRange
      const id = `${queryId}-${dateRange}`;
    
      // modify the deep-copied queryData
      queryData[id].loading = false;
      queryData[id].data = resp.data;
      queryData[id].error = null;
      
      if (refreshedAt) {
        queryData[id].refreshedAt = refreshedAt;
      }
    
      return {
        ...state,
        queryData,
      };
    },
    

    [runQueryByIdFailure]: (state, data) => {
      const { queryId, dateRange, error } = data.payload;
    
      // make a deep copy of the queryData in the state using JSON.parse and JSON.stringify
      const queryData = JSON.parse(JSON.stringify(state.queryData));
      
      // create an id based on the queryId and dateRange
      const id = `${queryId}-${dateRange}`;
    
      // modify the deep-copied queryData
      queryData[id].loading = false;
      queryData[id].data = null;
      queryData[id].refreshedAt = null;
      queryData[id].error = error;
      
      return {
        ...state,
        queryData,
      };
    },
    
    [reset]: () => ({
      ...initialState
    }),
  },
  initialState,
)

export default insightReducer;