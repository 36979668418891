import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { getOrders } from 'app/store/actions/order';
import { ExclamationCircle } from 'react-bootstrap-icons';
import { ordersDataSelector, ordersDataLoadingSelector, ordersDataErrorsSelector } from 'app/store/selectors/order';
import { Table, TableHeader, Pagination, LoadingAnimation, StatusChip, SearchBar, Card, Link } from 'app/components';
import { useTranslation } from 'react-i18next';
// import OrdersSubFilterMenu from './OrdersSubFilterMenu';
import OrdersFilterMenu from './OrdersFilterMenu';
import moment from 'moment';
import './index.scss';

const OrdersView = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  
  const ordersData = useSelector(ordersDataSelector);
  const loading = useSelector(ordersDataLoadingSelector);
  const errorsFound = useSelector(ordersDataErrorsSelector);
  
  const [searchParams, setSearchParams] = useSearchParams();
  const currentPage = parseInt(searchParams.get('page') || '1', 10);
  const pageSize = parseInt(searchParams.get('pageSize') || '50', 10);
  const searchString = searchParams.get('searchString') || null;
  const sortBy = searchParams.get('sortBy') || 'createdAt';
  const sortDir = searchParams.get('sortDir') || 'desc';
  const statusFilter = searchParams.get('status') || null;

  useEffect(() => {
    if (!searchString || searchString.length !== 1) {
      dispatch(getOrders({
        searchAll: searchString,
        status: statusFilter,
        page: currentPage,
        pageSize,
        sortBy,
        sortDir
      }));
    }
  }, [searchString, statusFilter, currentPage, pageSize, sortBy, sortDir, dispatch]);

  const onSearchStringUpdated = (searchString) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set('page', 1);
    searchString ? updatedSearchParams.set('searchString', searchString) : updatedSearchParams.delete('searchString');
    setSearchParams(updatedSearchParams.toString());
  }; 

  return (
    <div className="orders-view">
      {loading && <LoadingAnimation />}
      {(ordersData || errorsFound) && (
        <Card>
          <div className="orders-header">Orders</div>
          <OrdersFilterMenu disabled={errorsFound} />
          <div className="search-bar-and-filters">
            <SearchBar
              searchPlaceholderText="Search for an Order"
              onSearchStringUpdated={onSearchStringUpdated}
              debounceDelay={500}
              disabled={errorsFound}
            />
            {/* <OrdersSubFilterMenu /> */}
          </div>
          <Table size="medium">
            <TableHeader
              sortBy={sortBy}
              sortDir={sortDir}
              options={[
                { id: 'id', label: 'Order ID', orderable: !errorsFound },
                { id: 'externalId', label: 'Merchant Order ID', orderable: !errorsFound },
                { id: 'createdAt', label: 'Created', orderable: !errorsFound },
                { id: 'merchantName', label: 'Merchant', orderable: !errorsFound },
                { id: 'name', label: t('general.name') },
                { id: 'status', label: 'Status', align: 'center'},
              ]}
            />
            <tbody className="table-body">
              {!errorsFound && ordersData && ordersData.orders.map(order => (
                <tr className="order-row" key={order.id}>
                  <td><Link label={order.shortId} url={`/orders/${order.id}`} /></td>
                  <td className="safe-id">{order.externalId}</td>
                  <td className="created">{moment(order.createdAt).local().format('MMM DD, h:mm:ss a')}</td>
                  <td>{order.merchantName}</td>
                  <td>{`${order.shippingAddress.firstName} ${order.shippingAddress.lastName}`}</td>
                  <td className="order-status-chip"><StatusChip type="order" status={order.status} /></td>
                </tr>
              ))}
              {ordersData && ordersData.orders.length === 0 && (
                <tr>
                  <td colSpan="6" className="no-results">No results found</td>
                </tr>
              )}
              {errorsFound && !loading && (
                <tr>
                  <td colSpan="6" className="error-message"><ExclamationCircle />Error fetching order data</td>
                </tr>
              )}
            </tbody>
          </Table>
          <Pagination totalItems={ordersData?.total || 0} />
        </Card>
      )}
    </div>
  )
}

export default OrdersView;