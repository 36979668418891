import React, { useEffect } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { ToastContainer } from 'react-toastify';
import { userAuthSelector } from 'app/store/selectors/user';
import { isMerchant, isSuperAdmin, isVendor, usePermission } from 'app/permissions';
import Home from 'app/pages/Home';
import Orders from 'app/pages/Orders';
// import PlaceAnOrder from 'app/pages/PlaceAnOrder';
import Users from 'app/pages/Users';
import Merchants from 'app/pages/Merchants';
import Vendors from 'app/pages/Vendors';
import Routing from 'app/pages/Routing';
import Webhooks from 'app/pages/Webhooks';
import Integrations from 'app/pages/Integrations';
import Inventory from './pages/Inventory';
import SignIn from 'app/pages/Signin';
import ForgotPassword from './pages/ForgotPassword';
import { Sidebar, Topbar, MainContent } from 'app/components';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import 'typeface-source-sans-pro';
import './lang/i18n';
import './App.scss';

function App() {
  const userAuth = useSelector(userAuthSelector);

  // get user permissions
  const canGetOrders = usePermission('order', 'search');
  const canReadInsights = usePermission('insight', 'get');
  const canGetIntegrations = usePermission('transformation', 'get');
  const canGetStrategies = usePermission('routing', 'strategies_get');
  const canGetNotifications = usePermission('notification', 'get');
  const canSearchUsers = usePermission('users', 'search');
  const canCreateUsers = usePermission('users', 'users_create');
  const canSearchVendors = usePermission('vendor', 'search');
  const canSearchMerchants = usePermission('merchant', 'get');

  const superAdmin = isSuperAdmin();
  const vendor = isVendor();
  const merchant = isMerchant();

  const findNextAvailableRoute = () => {
    const routes = [
      { permission: canReadInsights, route: '/' },
      { permission: canGetOrders, route: '/orders' },
      { permission: canSearchUsers || canCreateUsers, route: '/admin/users' },
      { permission: canSearchVendors, route: '/admin/vendors' },
      { permission: canSearchMerchants, route: '/admin/merchants' },
      { permission: canGetStrategies, route: '/admin/routing' },
      { permission: canGetNotifications, route: '/admin/webhooks' },
      { permission: canGetIntegrations, route: '/admin/integrations' },
    ];

    const nextRoute = routes.find((route) => route.permission);
    return nextRoute ? nextRoute.route : '/';
  };

  return (
    <BrowserRouter>
      <div className="app">
        <ToastContainer pauseOnFocusLoss={false} hideProgressBar={true} draggable={false} />
        {userAuth.bearerToken ? (
          <>
            <ScrollToTop />
            <Topbar showOrderSearchBar={canGetOrders} />
            <div className="main-container">
              <Sidebar userData={{ superAdmin, vendor, merchant }} />
              <MainContent>
                <Routes>
                  <Route path="*" element={canReadInsights ? <Home /> : <Navigate to={findNextAvailableRoute()} replace />} />
                  {canGetOrders && <Route path="/orders/*" element={<Orders />} />}
                  {/* <Route path="/place-an-order/*" element={<PlaceAnOrder />} /> */}
                  {(canSearchUsers || canCreateUsers) && <Route path="/admin/users/*" element={<Users />} />}
                  {canSearchVendors && <Route path="/admin/vendors/*" element={<Vendors />} />}
                  {canSearchMerchants && <Route path="/admin/merchants/*" element={<Merchants />} />}
                  {canGetStrategies && <Route path="/admin/routing/*" element={<Routing />} />}
                  {canGetNotifications && <Route path="/admin/webhooks/*" element={<Webhooks />} />}
                  {canGetIntegrations && <Route path="/admin/integrations/*" element={<Integrations />} />}
                  <Route path="/admin/inventory/*" element={<Inventory />} />
                  <Route path="*" element={<Navigate to={findNextAvailableRoute()} replace />} />
                </Routes>
              </MainContent>
            </div>
          </>
        ) : (
          <Routes>
            <Route path="/signin" element={<SignIn />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/forgot-password/:token" element={<ForgotPassword />} />
            <Route path="*" element={<Navigate to="/signin" replace />} />
          </Routes>
        )}
      </div>
    </BrowserRouter>
  );
}

export default App;

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    });
  }, [pathname]);

  return null;
}