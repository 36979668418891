import React, { useState } from 'react';
import { ThreeDotsVertical } from 'react-bootstrap-icons';
import { Image, Table, TableHeader, ButtonIcon, Link, MessageBar, Button } from 'app/components';
import ReplaceImageModal from '../../../Modals/ReplaceImageModal';
import './index.scss';

const AdditionalItemDetails = (props) => {
  const { orderId, itemInfo } = props;
  const [showReplaceImageModal, setShowReplaceImageModal] = useState(null);

  const imageOptions = () => {
    return [
      {
        value: 'Replace Image',
        label: 'Replace Image',
        onClick: () => setShowReplaceImageModal(true),
      },
      // {
      //   value: 'View Image Details',
      //   label: 'View Image Details',
      //   onClick: () => console.log('View Image Details')
      // },
    ];
  };

  return (
    <div className="additional-item-details">
      <div className="details-container">
        <div className="item-image">
          <Image src={itemInfo.images[0].url || itemInfo.images[0].ourThumbnailUrl || itemInfo.images[0].ourUrl || itemInfo.images[0].thumbnailUrl} alt={itemInfo.name} />
        </div>
        {itemInfo.options && (
          <div className={`item-details ${itemInfo.options.length > 4 ? 'many' : ''}`}>
            {itemInfo.options.map(option => (
              <div className="item-property" key={option.name}>
                <div className="property-name">{option.name}</div>
                <div className="property-value">{option.value}</div>
              </div>
            ))}
          </div>
        )}
      </div>
      <div className="image-details">
        <Table size="small" className="image-details-table">
          <TableHeader
            options={[
              { id: 'Print Ready Image', label: 'Print Ready Image' },
              { id: 'Image Size', label: 'Image Size' },
              { id: 'Image Manip', label: 'Image Manip' },
              { id: 'Print Method', label: 'Print Method' },
              { id: 'Actions', label: 'Actions', align: 'center' },
            ]}
          />
          <tbody className="table-body">
            {itemInfo.images.map((image) => (
              <tr className="image-row" key={image.area}>
                <td className="our-url">
                  {image.ourUrl || image.url || image.thumbnailUrl ? (
                    <Link
                      label={`${image.area}.jpg` || 'View Image'}
                      isRoute={false}
                      url={image.url|| image.ourUrl || image.thumbnailUrl}
                    />
                  ) : (
                    <div>-</div>
                  )}
                </td>
                <td>-</td>
                <td>{image.manipCommand ? 'True' : 'False'}</td>
                <td>
                  {image.printMethod ? image.printMethod : '-'}
                </td>
                <td className="action-icon">
                  <ButtonIcon
                    icon={<ThreeDotsVertical />}
                    options={imageOptions(image)}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {itemInfo.status === 'imageerror' && (
          <MessageBar color="red" className="image-error-message-bar">
            <div>
              {`We encountered an item with an image issue. For more details `}
              <Link
                label="View History Logs"
                url="history"
              />.
            </div>
            <Button
              label="Replace Image"
              destructive
              size="small"
              onClick={() => setShowReplaceImageModal(true)}
            />
          </MessageBar>
        )}
      </div>
      {showReplaceImageModal && (
        <ReplaceImageModal
          orderId={orderId}
          itemId={itemInfo.id}
          setShowReplaceImageModal={setShowReplaceImageModal}
        />
      )}
    </div>
  );
}

export default AdditionalItemDetails;